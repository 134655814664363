<!--家校训练服务-上课-训练完成-->
<template>
  <div class="end-of-crouse">
    <div class="module module-1">
      <div class="title">
        <div class="title-l">课堂评语</div>
        <div class="title-r">
          <div v-if="isHideBtn && !isSchoolStatus">
            <a-button type="link" @click="isEdit = !isEdit" v-if="isEdit">编辑</a-button>
            <a-button type="link" @click="handleSaveComments" v-else>保存</a-button>
          </div>
        </div>
      </div>
      <div v-if="isEdit" style="width: 100%; height: 100px; overflow-y: auto">
        {{ descript || '暂无评语' }}
      </div>
      <div v-else style="position: relative">
        <a-textarea v-model="descript" placeholder="请输入评语，最多500字" :maxLength="500" allow-clear :rows="4" />
        <div style="position: absolute; bottom: 0px; right: 1px">
          <span style="background: #fff">{{ descript.length || 0 }}/500</span>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="title"><div class="title-l">行为记录</div></div>
      <a-row :gutter="[24, 16]" v-if="behaviorRecordResultList.length" style="margin-bottom: 24px">
        <a-col
          :lg="8"
          :md="12"
          :sm="24"
          v-for="(behaviorRecordItem, behaviorRecordIndex) in behaviorRecordResultList"
          :key="behaviorRecordIndex"
        >
          <a-card size="small" style="border-radius: 10px">
            <div slot="title">
              <span>{{ behaviorRecordItem.name }}</span>
              <span style="color: #3a5ff8; font-weight: normal"
                >（{{ behaviorRecordItem.recordingMethod | ClassBeginMethodText() }}）</span
              >
            </div>
            <div slot="extra" v-if="isEditBehaviorRecord">
              <a-button
                type="link"
                style="margin-right: 20px; color: #1c1c1c"
                v-if="behaviorRecordItem.flag"
                @click="handleCancel({ behaviorRecordItem, behaviorRecordIndex })"
                >取消</a-button
              >
              <a-button
                type="link"
                v-if="behaviorRecordItem.flag"
                @click="handleSave({ behaviorRecordItem, behaviorRecordIndex })"
                >保存</a-button
              >
              <a-button
                type="link"
                style="color: #1c1c1c"
                v-if="!behaviorRecordItem.flag && behaviorRecordItem.behaviorRecordResultDetailsVO.length"
                @click="behaviorRecordItem.flag = !behaviorRecordItem.flag"
                >修改</a-button
              >
            </div>
            <div class="behavior-record">
              <li
                v-for="(item, index) in behaviorRecordItem.behaviorRecordResultDetailsVO"
                :key="index"
                v-if="behaviorRecordItem.behaviorRecordResultDetailsVO"
              >
                <template v-if="behaviorRecordItem.recordingMethod === 'COUNT'"> {{ item.frequency }}次</template>
                <template v-if="['TIMING_COUNT', 'COUNTDOWN_COUNT'].indexOf(behaviorRecordItem.recordingMethod) > -1"
                  >{{ showTime(item.duration) }}，{{ item.frequency }}次
                </template>
                <template v-if="behaviorRecordItem.recordingMethod === 'TIMING'"
                  >{{ showTime(item.duration) }}
                </template>
                <a-icon
                  v-if="behaviorRecordItem.flag"
                  slot="count"
                  type="line"
                  @click="deleteMaterial({ behaviorRecordItem, item, index })"
                  style="
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    padding: 2px;
                    top: -10px;
                    right: -4px;
                    border-radius: 50%;
                    background-color: #f10101;
                    color: #fff;
                  "
                />
              </li>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-row v-else type="flex" justify="start" style="margin-bottom: 24px">本课堂无行为记录</a-row>
      <div class="title">
        <div class="title-l">课堂记录</div>
        <div>总训练次数：{{ data.trainTotalNum }}次</div>
      </div>
      <a-row :gutter="[16, 16]" class="class-record">
        <a-col :lg="12" :md="24" :sm="24" v-for="(item, index) in classRecordList" :key="index">
          <a-card
            size="small"
            :title="item.skillName || '--'"
            style="border-radius: 10px; overflow: hidden; position: relative; height: 216px"
          >
            <div class="class-record-content">
              <div class="phase">第{{ convertToChineseNumber(item.phaseNumber) }}阶段：{{ item.name }}</div>
              <div class="buttons" v-if="isClassRecord">
                <a-button
                  type="link"
                  v-if="!isSchoolStatus && $auth('teaching_class_record_update_phase_material')"
                  style="margin-right: 24px"
                  @click="$emit('change', { current: 2, item })"
                  >编辑阶段素材</a-button
                ><a-button
                  v-if="$auth('teaching_class_record_select_detail')"
                  type="link"
                  @click="$emit('change', { current: 3, item })"
                  >记录详情</a-button
                >
              </div>
              <div class="tag">
                <div class="tag-l">{{ item.goal | RecordingMethodText() }}</div>
                <div class="tag-c">
                  {{
                    item.goal === 'DURATION'
                      ? '目标时长：'
                      : item.goal === 'NUMBER_SUCCESS'
                      ? '目标次数：'
                      : '目标正确率：'
                  }}
                  {{ item.correctRate }}{{ ['SUCCESS_RATE', 'STEP_SUCCESS_RATE'].indexOf(item.goal) > -1 ? '%' : '' }}
                </div>
                <div class="tag-r">
                  最低训练次数：
                  {{ item.minimumRounds }}次
                </div>
              </div>
              <ul class="material">
                <template v-if="item.materialResultMap && item.materialResultMap.length == 1">
                  <li v-for="(materialItem, materialIndex) in item.materialResultMap" :key="materialIndex">
                    <a-badge :color="isUpToStandard(item, materialItem) ? '#52BE7F' : '#3A5FF8'" />
                    {{ materialItem.material }}：训练{{ materialItem.number }}次，{{
                      item.goal === 'NUMBER_SUCCESS' ? '平均次数' : '正确率'
                    }}{{ materialItem[method[item.goal]] }}{{ item.goal === 'NUMBER_SUCCESS' ? '次' : '%' }}；
                    <a-icon
                      type="check-circle"
                      theme="twoTone"
                      two-tone-color="#52BE7F"
                      :style="{ fontSize: '20px' }"
                      v-if="isUpToStandard(item, materialItem)"
                    />
                  </li>
                </template>
                <template v-if="item.materialResultMap && item.materialResultMap.length > 1">
                  <a-col
                    :lg="12"
                    :md="24"
                    :sm="24"
                    v-for="(materialItem, materialIndex) in item.materialResultMap"
                    :key="materialIndex"
                  >
                    <li>
                      <a-badge :color="isUpToStandard(item, materialItem) ? '#52BE7F' : '#3A5FF8'" />
                      {{ materialItem.material }}：训练{{ materialItem.number }}次，{{
                        item.goal === 'NUMBER_SUCCESS' ? '平均次数' : '正确率'
                      }}
                      {{ materialItem[method[item.goal]] }}{{ item.goal === 'NUMBER_SUCCESS' ? '次' : '%' }}；
                      <a-icon
                        type="check-circle"
                        theme="twoTone"
                        two-tone-color="#52BE7F"
                        :style="{ fontSize: '20px' }"
                        v-if="isUpToStandard(item, materialItem)"
                      />
                    </li>
                  </a-col>
                </template>
              </ul>
              <div class="status-badge" v-if="item.isFinish">已完成</div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { convertToChineseNumber } from '@/utils/util'
import * as Api from '@/api/serviceManager'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'
export default {
  props: {
    // 隐藏评语右上角按钮
    isHideBtn: {
      type: Boolean,
      default: true,
    },
    // 是否可以编辑评语
    isEdit: {
      type: Boolean,
      default: true,
    },
    // 课堂记录-编辑和详情
    isClassRecord: {
      type: Boolean,
      default: true,
    },
    // 行为记录-修改
    isEditBehaviorRecord: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      descript: '',
      tempDelete: {}, // 删除的临时数据
      // 课堂行为记录
      behaviorRecordResultList: [],
      // 技能阶段记录
      classRecordList: [],
      data: {},
      teachingClassId: '',
      // 课堂记录-每个素材-正确率-不同类型用不同的字段
      method: {
        SUCCESS_RATE: 'rate',
        DURATION: 'rate',
        NUMBER_SUCCESS: 'avgFrequency',
        STEP_SUCCESS_RATE: 'avgRate',
      },
    }
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus', // 学生-在园状态【true离园  false在园】
    }),
  },
  methods: {
    // 保存评语
    handleSaveComments() {
      const comments = this.descript
      if (!comments) {
        this.$notification['error']({ message: '请填写课堂评语' })
        // eslint-disable-next-line
        return
      }
      const { teachingClassId: id } = this
      const params = { comments, id }
      Api.finishComments(params).then((res) => {
        const { code } = res
        // code === 0 && this.homeSchoolDetail()
        if (code === 0) {
          this.isEdit = !this.isEdit
          this.data = { ...this.data, ...{ comments: this.descript } }
        }
      })
    },

    initData({ data, teachingClassId }) {
      const { descript } = this
      const { behaviorRecordResultList, classRecordList, comments } = data
      this.teachingClassId = teachingClassId
      if (!descript) {
        this.descript = comments || ''
      }
      // 操作标记加进去
      this.behaviorRecordResultList = behaviorRecordResultList
        ? behaviorRecordResultList.map((behaviorRecordItem) => {
            return { ...behaviorRecordItem, flag: false }
          })
        : []

      this.classRecordList = classRecordList
      this.data = cloneDeep({ ...data, ...{ behaviorRecordResultList: this.behaviorRecordResultList } })
    },
    // 取消
    handleCancel({ behaviorRecordIndex }) {
      this.$set(
        this.behaviorRecordResultList,
        behaviorRecordIndex,
        cloneDeep(this.data.behaviorRecordResultList[behaviorRecordIndex])
      )
    },
    // 保存
    handleSave({ behaviorRecordItem, behaviorRecordIndex }) {
      const { behaviorRecordResultDetailsVO: arrB } = behaviorRecordItem
      const arrA = this.data.behaviorRecordResultList[behaviorRecordIndex].behaviorRecordResultDetailsVO
      if (arrB.length === arrA.length) {
        this.$emit('initData')
        return
      }
      const classBehaviorRecordList = []
      arrA.map((itemA) => {
        const key = arrB.findIndex((itemb) => itemA.id === itemb.id)
        if (key === -1) {
          classBehaviorRecordList.push(itemA.id)
        }
      })
      const { teachingClassId } = this

      const params = {
        classBehaviorRecordList,
        teachingId: teachingClassId,
      }
      Api.deleteClassBehaviorRecord(params).then((res) => {
        const { code } = res
        if (code === 0) {
          this.$emit('initData')
        }
      })
    },
    // 删除
    deleteMaterial({ behaviorRecordItem, item, index }) {
      const confirm = this.$confirm({
        title: '确定删除',
        content: `行为记录：${behaviorRecordItem.name}`,
        onOk: () => {
          behaviorRecordItem.behaviorRecordResultDetailsVO.splice(index, 1)
          confirm.destroy()
        },
      })
    },
    showTime(seconds) {
      let minute = '00'
      let second = '00'
      if (seconds < 60) {
        second = this.formatTime(seconds)
      } else {
        minute = this.formatTime(parseInt(seconds / 60))
        second = this.formatTime(parseInt(seconds % 60))
      }
      return `${minute}分${second}秒`
    },
    // 格式化数字 往前补0
    formatTime(number) {
      return number < 10 ? `0${number}` : number
    },
    // 是否达标
    isUpToStandard(item, materialItem) {
      return item.goal === 'NUMBER_SUCCESS'
        ? materialItem.avgFrequency >= item.frequency
        : materialItem.rate >= item.successRate
    },
    convertToChineseNumber,
  },
}
</script>
<style lang="less" scoped>
.end-of-crouse {
  background: #f7f8fc;
  /deep/ .ant-card-head {
    background: #e1e8ff;
    border-radius: 10px 10px 0 0;
    border-bottom-width: 1px !important;
    .ant-card-head-title {
      font-size: 14px;
      font-weight: 600;
      color: #1a1a1a;
      line-height: 28px;
    }
  }
  .complete {
    padding: 24px 32px 0 32px;
    background: #fff;
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 32px;
    img {
      width: 32px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .module {
    width: 100%;
    background: #ffffff;
    // border-radius: 10px;
    padding: 24px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 16px;
      .title-l {
        font-size: 16px;
        font-weight: 600;
        color: #1a1a1a;
      }
    }
    /*行为记录*/
    .behavior-record {
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;
      height: 149px;
      overflow-y: scroll;
      align-content: flex-start;
      padding-top: 9px;
      li {
        position: relative;
        padding: 0 4px;
        min-width: 90px;
        height: 33px;
        border-radius: 2px;
        border: 1px solid #e1e8ff;
        text-align: center;
        font-size: 14px;
        color: #1a1a1a;
        line-height: 33px;
        margin-right: 12px;
        margin-bottom: 8px;
      }
    }

    /*课堂记录*/
    .class-record {
      /deep/ .ant-card-head {
        background: #3a5ff8;
        .ant-card-head-title {
          color: #fff;
        }
      }

      /deep/ .ant-card-body {
        max-height: 170px;
        overflow-y: auto;
      }
      .class-record-content {
        .phase {
          font-weight: 600;
          color: #2f373c;
          margin-bottom: 8px;
        }
        .buttons {
          margin-bottom: 8px;
          button {
            height: 20px;
          }
        }
        .tag {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 10px;
          .tag-l {
            padding: 0px 10px;
            width: fit-content;
            background: rgba(58, 95, 248, 0.1);
            border-radius: 13px;
            font-weight: 600;
            color: #2f373c;
            margin-right: 10px;
          }
          .tag-r,
          .tag-c {
            color: #000000;
            opacity: 0.6;
          }

          .tag-r {
            margin-left: 24px;
          }
        }

        .material {
          display: flex;
          flex-wrap: wrap;
          background: #ffffff;
          min-height: 70px;
          align-content: flex-start;
          padding-left: 0;
          li {
            display: flex;
            justify-content: flex-start;
            position: relative;
            width: fit-content;
            /*text-align: left;*/
            font-size: 14px;
            color: #2f373c;
            font-weight: 600;
          }
        }
        .status-badge {
          font-size: 13px;
          line-height: 47px;
          width: 100px;
          background: #34ca71;
          color: #fff;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          transform-origin: left bottom;
          transform: translate(29.29%, -100%) rotate(45deg);
          text-indent: 0;
          text-align: center;
        }
      }
    }
  }

  .module-1 {
    border-radius: 0;
    // border-top-left-radius: 0px;
    // border-top-right-radius: 0px;
    padding: 0 24px 0 24px;
    // margin-bottom: 16px;
  }

  /*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  /*定义滚动条轨道
   内阴影+圆角*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0);
  }
  /*定义滑块
   内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(144, 147, 153, 0.3);
  }
}
</style>
