<!--家校服务-上课记录-记录详情-->
<template>
  <div>
    <a-card :bodyStyle="{ padding: '20px 24px' }">
      <div class="head">
        <div style="font-size: 16px; font-weight: 600; color: #333333">记录详情</div>
        <div class="head-right">
          <div v-if="showTime">
            {{ (data && data.date && data.date.year) || '' }}-{{ (data && data.date && data.date.month) || '' }}-{{
              (data && data.date && data.date.day) || ''
            }}
          </div>
          <a-select
            v-if="showTime"
            v-model="data.currentTime"
            placeholder="选择时间段"
            style="width: 140px"
            @change="changeCurrentTime"
          >
            <a-select-option
              v-for="dateTimeItem in data.dateTimeList"
              :key="dateTimeItem.id"
              :value="dateTimeItem.id"
              >{{ dateTimeItem.name }}</a-select-option
            >
          </a-select>
          <div class="teacher">本次上课老师：{{ data.teacherName }}</div>
          <a-button @click="$emit('back')" style="margin-left: 24px"><a-icon type="left" />返回</a-button>
        </div>
      </div>
      <div style="margin-bottom: 16px">
        <span style="font-size: 16px; font-weight: 600; color: #333333; margin-right: 32px">{{
          data.skillName || '--'
        }}</span>
        <span
          >第{{ convertToChineseNumber(data.recordDetailsPhase.phaseNumber) }}阶段：
          {{ data.recordDetailsPhase.phaseName || '--' }}
        </span>
      </div>
      <div class="type-line">
        <div class="goal">{{ data.recordDetailsPhase.goal | RecordingMethodText() }}</div>

        <div>
          <span class="label">目标正确率：</span>{{ data.recordDetailsPhase.correctRate
          }}{{ ['SUCCESS_RATE', 'STEP_SUCCESS_RATE'].indexOf(data.recordDetailsPhase.goal) > -1 ? '%' : '' }}
        </div>
        <div><span class="label">最低训练次数：</span>{{ data.recordDetailsPhase.minimumRounds }}次</div>
        <div>
          <span class="label"> 训练辅助方式： </span>
          <span v-if="trainingAssistanceModeList.length">
            <span
              v-for="(trainingAssistanceMode, trainingAssistanceModeIndex) in trainingAssistanceModeList"
              :key="trainingAssistanceModeIndex"
            >
              <span class="number">{{ trainingAssistanceMode.id }}</span>
              <span class="number-name">{{ trainingAssistanceMode.name }}</span>
            </span>
          </span>

          <span v-else>您已选择不需要记录辅助</span>
        </div>
      </div>
      <a-table
        class="record-table"
        :columns="columns"
        :data-source="data.recordDetailsPhase.teachingClassTraining"
        bordered
        :scroll="{ x: true }"
        :pagination="false"
        :rowKey="
          (record, index) => {
            return index
          }
        "
      >
        <template slot="materialName" slot-scope="text, record">
          <div key="materialName">
            <div style="font-size: 14px; color: #2f373c; font-weight: 600">{{ record.materialName }}</div>
            <div style="font-size: 12px; color: #666666">
              {{
                `训练${record.round}次/${
                  data.recordDetailsPhase.goal === 'DURATION'
                    ? '时长:'
                    : data.recordDetailsPhase.goal === 'NUMBER_SUCCESS'
                    ? '平均次数'
                    : '正确率:'
                }${data.recordDetailsPhase.goal === 'NUMBER_SUCCESS' ? record.frequency : record.correctRate}${
                  ['SUCCESS_RATE', 'STEP_SUCCESS_RATE'].indexOf(data.recordDetailsPhase.goal) > -1 ? '%' : ''
                }`
              }}
            </div>
          </div></template
        >
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { convertToChineseNumber } from '@/utils/util'
import { cloneDeep } from 'lodash'

export default {
  name: 'ClassRecordDetail',
  props: {
    showTime: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [],
      // 记录详情
      data: {
        skillName: '',
        date: {
          year: '',
          month: '',
          day: ''
        }, // 日期
        dateTimeList: [], // 时间列表
        currentTime: '', // 选中的时间
        skillList: [], // 左侧树状
        // 右侧记录详情
        recordDetailsPhase: {
          phaseNumber: 0
        }
      },
      trainingAssistanceModeList: []
    }
  },
  methods: {
    initData(data) {
      this.data = cloneDeep(data)
      const {
        recordDetailsPhase: { trainingAssistanceMode1, trainingAssistanceMode2, trainingAssistanceMode3 }
      } = data
      // 训练辅助方式PP123
      const tempTrainingAssistanceMode = cloneDeep(
        [trainingAssistanceMode1, trainingAssistanceMode2, trainingAssistanceMode3].filter(Boolean)
      )
      this.trainingAssistanceModeList = []
      tempTrainingAssistanceMode &&
        tempTrainingAssistanceMode.forEach((item, index) => {
          this.trainingAssistanceModeList.push({
            id: `PP${index + 1}`,
            name: item
          })
        })
      this.columns = []
      this.getColumns()
    },
    // 切换时间
    changeCurrentTime(value) {
      // console.log('changeCurrentTime', value)
      this.$emit('changeCurrentTime', value)
    },
    getColumns() {
      // console.log(this.data)
      const {
        recordDetailsPhase: { teachingClassTraining }
      } = cloneDeep(this.data)
      const columns = [
        {
          title: '训练素材',
          dataIndex: 'materialName',
          align: 'center',
          width: 180,
          scopedSlots: { customRender: 'materialName' }
        },
        {
          title: '数据记录',
          align: 'left',
          children: []
        }
      ]
      // 最大的回合数
      const maxResult = Math.max(
        ...cloneDeep(teachingClassTraining).map(item => {
          return item.trainingResults.flat(Infinity).length
        })
      )
      if (maxResult > 10) {
        const item = cloneDeep(columns)[0]
        this.$set(columns, 0, { ...item, ...{ fixed: 'left' } })
      }

      for (let i = 0; i < maxResult; i++) {
        // 定义 children 中的 column
        columns[1].children.push({
          title: `${i + 1}次`,
          width: 100,
          dataIndex: `childrenKey${i + 1}`,
          key: `childrenKey${i + 1}`,
          align: 'center',
          customRender: (value, row, index) => {
            return (
              <div>
                {value === 'true' ? (
                  <a-icon
                    type="check"
                    style={{
                      color: '#F12A2C',
                      fontSize: '17px'
                    }}
                  />
                ) : value === 'false' ? (
                  <a-icon
                    type="close"
                    style={{
                      color: '#52BE7F',
                      fontSize: '17px'
                    }}
                  />
                ) : (
                  value
                )}{' '}
              </div>
            )
          }
        })
      }
      // 拼装children里用的key和value
      const result = cloneDeep(teachingClassTraining).map((item, index) => {
        const trainingResults = item.trainingResults.flat(Infinity)
        const temp = {}
        for (let i = 0; i < maxResult; i++) {
          temp[`childrenKey${i + 1}`] = trainingResults[i]
        }

        return { ...item, ...temp }
      })
      this.data.recordDetailsPhase.teachingClassTraining = result

      this.columns = columns
    },
    convertToChineseNumber
  }
}
</script>

<style scoped lang="less">
.record-table {
  /deep/ .ant-table td {
    /*white-space: nowrap;*/
  }

  /deep/ .ant-table-thead th {
    background: #f7f8fc !important;
    font-size: 14px !important;
    font-weight: 600;
  }
}
/*顶部*/
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  background: #fff;
  .head-right {
    display: flex;
    align-items: center;
    > div {
      margin-left: 24px;
    }
    .teacher {
      font-size: 14px;
      font-weight: 600;
      color: #1a1a1a;
    }
  }
}
.type-line {
  display: flex;
  margin-bottom: 24px;
  > div {
    margin-right: 24px;
  }
  .goal {
    padding: 2px 16px;
    height: 20px;
    background: rgba(58, 95, 248, 0.1);
    border-radius: 10px;
    font-size: 12px;
    color: #2f373c;
  }

  .label {
    font-weight: 600;
  }

  .number {
    padding: 4px 8px;
    font-size: 12px;
    text-align: center;
    background: #e1e8ff;
    border-radius: 2px;
    color: #3a5ff8;
    font-weight: 600;
    margin-right: 6px;
  }
  .number-name {
    font-size: 14px;
    color: #1a1a1a;
    margin-right: 18px;
  }
}
</style>
